import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import { EntityWarningTabContent } from '../TabContent/EntityWarningTabContent';
// import { ProfileCardComponent } from '@internal/muziris-profile-card-plugin';

export const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {EntityWarningTabContent}
        <Grid item xs={12} md={6}>
          {/* <ProfileCardComponent /> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
